@import 'src/assets/styles/partials/typography';

.banner {
  background: linear-gradient(130.3deg, #AEECE9 26.18%, #FFFFFF 100%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14),
    0px 2px 3px -2px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
  .title {
    @include typography(34px, 500, 0.16px, 40px, center, rgba(0, 0, 0, 0.87));
  }
  .balloon {
    position: absolute;
    z-index: 2;
    margin-left: 80px;
    margin-top: 15px;

  }
  .body-img {
    position: absolute;
    margin-left: 50px;
    margin-top: 20px;
    z-index: 1;
  }
  .braco {
    position: absolute;
    margin-left: 120px;
    margin-top: 103px;
    z-index: 2;
  }
  .olhos {
    position: absolute;
    margin-left: 237px;
    margin-top: 65px;
    z-index: 3;
  }
  .circle {
    width: 700px;
    height: 700px;
    position: relative;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    .grid {
     margin-top: -140px;
      margin-left: -50px;
    }
  }

}

