@import 'src/assets/styles/partials/typography';

.search-layout {
  .description {
    @include typography(13px, 400, 0.16px, 15.23px, left, rgba(0, 0, 0, 0.6));
  }
}

.empty-data .empty-data-image {
  min-height: 120px;
  min-width: 120px;
}

.empty-data .empty-data-subtitle {
  @include typography(
    14px,
    normal,
    0.466667px,
    16px,
    center,
    rgba(0, 0, 0, 0.3)
  );
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
