@import 'src/assets/styles/partials/typography';

.modalIntroLayouts {
  .title {
    @include typography(34px, 400, 0.25px, 40px, left, #000000);
  }

  .sub-title {
    @include typography(20px, 500, 0.15px, 24px, left, rgba(0, 0, 0, 0.87));

  }

  .description {
    @include typography(16px, 400, 0.15px, 19px, left, rgba(0, 0, 0, 0.87));
  }

  .boxVideo {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    .BoxVideoFooter {
      display: flex;
      justify-content: space-between;
      margin: 20px;
    }
  }

  button:disabled,
  button[disabled]{
    opacity: 0;
  }

  .clear {
    cursor: pointer;
  }
}


