
.visualStyleClass{
  height: 671px;

  @media only screen and (max-width : 768px) {
    height: auto
  }
}

iframe {
  border: none;
}
