@import 'src/assets/styles/partials/typography';

.modalTreatmentDuplicate {
  .description {
    @include typography(16px, 400, 0.15px, 23px, left,  rgba(0, 0, 0, 0.87));
    .wordColor {
      color: #8A00C1
    }
  }
  .clear {
    cursor: pointer;
  }

}
