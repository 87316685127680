@import 'src/assets/styles/partials/typography';

.drawer-layout {
  .label-bar {
    @include typography(20px, 400, 0.16px, 15.23px, left, rgba(0, 0, 0, 0.87));
  }
  .layout {
    .layout-title {
      @include typography(12px, 400, 0.16px, 14px, left, rgba(0, 0, 0, 0.6));
    }

    .layout-sub-title {
      @include typography(24px, normal, 0.16px, 28px, left, rgba(0, 0, 0, 0.87));
    }

    .layout-description {
      @include typography(16px, normal, 0.15px, 24px, left, rgba(0, 0, 0, 0.6));
    }
  }
  .panel-description {
    margin-top: -9px;
    .list-item {
      padding-top: 9px;
      @include typography(16px, normal,  0.5px, 24px, left, rgba(0, 0, 0, 0.87));
    }
  }
  * p {
    padding: 2px;
  }
}
