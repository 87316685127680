
  .MuiTableRow-hover {
    .drawerIcon {
      opacity: 0;
      transition: opacity 0.5s;
    }
  }
  .MuiTableRow-hover:hover {
    .drawerIcon {
      opacity: 1;
    }
  }

