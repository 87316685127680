@import 'src/assets/styles/partials/typography';

.card-contaimer {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
  .title {
    font-weight: 400;
    font-size: 24px;
  }
  .sub-title {
    @include typography(16px, 400, null, 18.75px, left, rgba(0, 0, 0, 0.6));
  }
  * p {
    padding: 4px;
  }
}

