@import 'src/assets/styles/partials/typography';

.drawerLogs {
  .TimeLineItemName {
    @include typography(14px, 400, 0.16px, 20px, left, rgba(0, 0, 0, 0.87));
  }
  .TimeLineItemDepartment {
    @include typography(12px, 400, 0.16px, 16px, left, rgba(0, 0, 0, 0.68));
  }
  .TimeLineItemDate {
    @include typography(10px, 500, 1px, 16px, left, rgba(0, 0, 0, 0.60));
  }
  .TimeLineItemType {
    @include typography(12px, 500, 0.4px, 16px, left, rgba(0, 0, 0, 0.87));
  }
  .layout {
    .layoutTitle {
      @include typography(12px, 400, 0.16px, 14px, left, rgba(0, 0, 0, 0.6));
    }

    .layoutSubTitle {
      @include typography(24px, normal, 0.16px, 28px, left, rgba(0, 0, 0, 0.87));
    }

    .layoutDescription {
      @include typography(16px, normal, 0.15px, 24px, left, rgba(0, 0, 0, 0.6));
    }
  }
  .panelDescription {
    margin-top: -9px;
    .listItem {
      padding-top: 9px;
      @include typography(16px, normal,  0.5px, 24px, left, rgba(0, 0, 0, 0.87));
    }
  }
  * p {
    padding: 2px;
  }
}
