@import 'src/assets/styles/partials/typography';

.bannerLayouts {
  .banner {
    background: linear-gradient(91.23deg, #06C8CB 27.29%, #69FDFF 100%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14),
    0px 2px 3px -2px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);

    animation-name: animeBannerOrSnackBar;
    animation-duration: 0.7s;
    animation-delay: 2s;
    animation-fill-mode: backwards;


  }
  .title {
    @include typography(24px, 500, 0.16px, 28px, center, rgba(255, 255, 255, 0.87));
  }



  .duvidaModal {
    margin-top: -68px;
    margin-left: -20px;
    animation: float 5s ease-in-out infinite;
  }

}



@keyframes float {
  0% {
    transform: translateY(0px);

  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}



@keyframes animeBannerOrSnackBar {
  0% {

   margin-top: -150px;
    opacity: 0;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}
