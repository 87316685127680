@import 'src/assets/styles/partials/typography';

.treatment-success-modal {
  .title {
    @include typography(34px, 400, 0.25px, 39px, left, #000000);
  }
  .sub-title {
    @include typography(16px, normal, 0.16px, 19px, left, rgba(0, 0, 0, 0.87));
    .name-file {
      @include typography(16px, normal, 0.16px, 19px, left, #8A00C1);
    }
  }
  .image-treatment{
    max-width: 100%;
    height: 330px;
  }

  .operation-title {
    @include typography(14px, 500, 0.15px, 16px, left, rgba(0, 0, 0, 0.87));
  }

}
