// Variables
$topBarHeight: 64px;
$sidebarWidthClosed: 54px;
$sidebarWidthOpen: 225px;

.dashboard-layout {
  .appbar {
    box-shadow: 0px 3px 6px #00000033;

    .topbar {
      justify-content: space-between;
      flex-direction: row;

      .item {
        display: flex;
        align-items: center;
      }

      .logo-img {
        height: 36px;
        width: auto;
        display: flex;
        margin-top: -25px;
      }

      .user {
        display: flex;
        align-items: center;

        .info {
          margin: 0 10px;
        }
      }
    }
  }

  @media screen and (min-width: 960px) {
    .sidebar {
      flex-shrink: 0;
      white-space: nowrap;

      .paper {
        width: $sidebarWidthOpen;
        padding-top: $topBarHeight;
        flex-shrink: 0;
        white-space: nowrap;
        transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        z-index: inherit;
      }

      .close {
        overflow-x: hidden;
        width: $sidebarWidthClosed;
        flex-shrink: 0;
        white-space: 'nowrap';
        transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      }
    }
  }

  .MuiListItem-root.Mui-selected {
    background: inherit;
  }
}
