@import 'src/assets/styles/partials/typography';

.search-layout-modal {
  .title {
    @include typography(20px, 500, 0.15px, 23px, left, rgba(0, 0, 0, 0.85));
  }
  .sub-title {
    @include typography(16px, normal, 0.15px, 19px, left, rgba(0, 0, 0, 0.6));
  }

}
