@import 'src/assets/styles/partials/typography';
@import 'src/assets/styles/partials/variables';

.download-layout {
  box-shadow: $box-shadow;


  .title {
    @include typography(14px, normal, 0.25px, 16px, left, rgba(0, 0, 0, 0.87));
  }
  .sub-title {
    @include typography(14px, normal, 0.25px, 16px, left, rgba(0, 0, 0, 0.6));
  }
  .file-title {
    margin-top: 5px;
    @include typography(14px, normal, 0.25px, 16px, left, rgba(138,0,193,1));
  }
}

