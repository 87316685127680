@import 'src/assets/styles/partials/typography';
.step{
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.download {
  visibility: visible;
  opacity: 1;
}
.upload {
  visibility: visible;
  opacity: 1;
}



