@import 'src/assets/styles/partials/typography';

.insufficient-funds-modal {
  .title {
    @include typography(20px, 500, 0.15px, 23px, left, #000000);
  }
  .sub-title {
    @include typography(14px, 400, 0.15px, 16.41px, left, rgba(0, 0, 0, 0.87));
  }

}
