@import 'assets/styles/page.dashboardLayout';


html {
  scroll-behavior: smooth;
}

.first-capitalize {
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}

.break-word {
  word-wrap: break-word;
}

.active-animation {
  animation-duration: 0.5s;
  animation-name: pulse;
  animation-iteration-count: 5;
  animation-fill-mode: forwards;
  animation-direction: alternate;
}

.button-animation {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  animation: fadeInFromNone 0.3s linear;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
  }
}

.basecerta-layout {
  box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
  0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
}

@keyframes pulse {
  from {
    transform: translateY(0px);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    }
  to {
      box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
      transform: translateY(-9px);
    }
}


@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

