@import 'src/assets/styles/partials/typography';
@import 'src/assets/styles/partials/variables';
@import "src/assets/styles/partials/noselect";

.checkPurposesOfUse {
  display: grid;
}

.checkPurposesOfUseTitle {
  .checkPurposesOfUseLabel {
    @include typography(14px, 500, 0.15px, 23.44px, left, rgba(0, 0, 0, 0.87));
  }
}

.checkPurposesOfUseColumns {
  grid-template-columns: 50% 50%;
  span:last-child {
    @include typography(14px, normal, 0.15px, 23.44px, left, rgba(0, 0, 0, 0.6));
    @include noSelect();
  }
}

.checkPurposesOfUseLegend {
  .checkPurposesOfUseLegendText {
    @include typography(12px, normal, 0.15px, 16px, left, rgba(0, 0, 0, 0.6));
  }
}


