@import 'src/assets/styles/partials/typography';
@import 'src/assets/styles/partials/variables';

.record {
  .title {
    @include typography(34px, normal, 0.25px, 40px, left, rgba(0, 0, 0, 0.87));
  }
  .subTitle {
    @include typography(16px, normal, 0.25px, 19px, left, rgba(0, 0, 0, 0.6));
  }
  .allItems {
    @include typography(16px, normal, 0.15px, 19px, left, rgba(0, 0, 0, 0.6));
  }
  .borderBottom{
    border-bottom: 2px solid #F1F1F1;
  }
  .tabPanel{
    display:none
  }

}
