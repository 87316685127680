.progressCircleUI {
  .singleChart {
    width: 33%;
    justify-content: space-around;
  }

  .circularChart {
    position: absolute;
    transform: rotate(-90deg);
  }
  .circleBg {
    fill: none;
    stroke-width: 2.2;
  }


  .circle {
    fill: none;
    stroke-linecap: round;
  }

}




