@mixin typography($size: 16px,
  $weight: normal,
  $letterSpacing: 0.16px,
  $lineHeight: 16px,
  $textAlign: left,
  $color: rgba(255, 255, 255, 0.87)) {
    font-size: $size;
    font-weight: $weight;
    letter-spacing: $letterSpacing;
    line-height: $lineHeight;
    text-align: $textAlign;
    color: $color;
}

