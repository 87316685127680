.recordsDashTable{
  .statusProgress {
    animation: fadeInFromNone 1s infinite;
  }
}


@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0.3;
  }

  50% {
    display: block;
    opacity: 1;
  }

  100% {
    display: block;
    opacity: 0.3;
  }
}


