@import 'src/assets/styles/partials/typography';
@import 'src/assets/styles/partials/variables';


.CardTreatmentCanceled {
  box-shadow: $box-shadow;

  .title {
    @include typography(20px, 500, 0.15px, 23.44px, left, rgba(0, 0, 0, 0.87));
  }
  .detail {
    @include typography(14px, normal, 0.15px, 16px, left, rgba(0, 0, 0, 0.87));
  }

  .error {
    @include typography(14px, 500, 0.15px, 16px, left, rgba(176,0,32,1));
  }
  .detailPopover {
    @include typography(14px, normal, 0.15px, 16px, left, rgba(0, 0, 0, 0.87));
  }
  .detailBold {
    @include typography(14px, 500, 0.15px, 16px, left, rgba(0, 0, 0, 0.87));
  }
  .detailProgress {
    @include typography(14px, 400, 0.15px, 16px, left, rgba(0, 0, 0, 0.6));
  }
  .animacao {
    animation: fadeInFromNone 1s infinite;
  }

}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0.3;
  }

  50% {
    display: block;
    opacity: 1;
  }

  100% {
    display: block;
    opacity: 0.3;
  }
}
