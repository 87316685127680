@import 'src/assets/styles/partials/typography';
@import 'src/assets/styles/partials/variables';

.dashboard {

  .boxDragLayout{
    animation: fadeInFromNone 1s forwards;
    .dragTextColor {
      @include typography(34px, normal, 0.15px, 36px, center, #fff);
    }
  }

}






@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }


  100% {
    opacity: 0.8;
  }
}

