@import 'src/assets/styles/partials/typography';

.intro-modal {
  .title {
    @include typography(34px, 400, 0.25px, 40px, left, #000000);
  }

  .sub-title {
    @include typography(20px, 500, 0.15px, 24px, left, rgba(0, 0, 0, 0.87));

  }

  .description {
    @include typography(16px, 400, 0.15px, 19px, left, rgba(0, 0, 0, 0.87));
  }
}


