@import 'src/assets/styles/partials/typography';
@import 'src/assets/styles/partials/variables';
.layoutsPage {
  .title {
    @include typography(34px, normal, 0.25px, 40px, left, rgba(0, 0, 0, 0.87));
  }
  .subTitle {
    @include typography(16px, normal, 0.25px, 19px, left, rgba(0, 0, 0, 0.6));
  }
}
.bgColorSnackBar {
  background-color: rgba(0, 0, 0, 0.87);
  .textColor {
    color: #BB86FC;
  }
  .closeColor {
    color: #FFF;
  }
}

.switchLabel {
  color: rgba(0, 0, 0, 0.87);
  span {
    font-size: 12px;
  }
}
.emptyTable {
    div {
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0.46667px;
      line-height: 16px;
      text-align: center;
      color: rgba(0, 0, 0, 0.3);
    }
}

