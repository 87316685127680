@import 'src/assets/styles/partials/typography';
@import 'src/assets/styles/partials/variables';

.disabledBox {
  opacity: 0.38;
}

.cardTreatmentProgress {
  box-shadow: $box-shadow;

  .title {
    @include typography(20px, 500, 0.15px, 23.44px, left, rgba(0, 0, 0, 0.87));
  }
  .detail {
    @include typography(14px, normal, 0.15px, 16px, left, rgba(0, 0, 0, 0.87));
  }

  .spacing {
    * {
      padding:1px;
    }

  }

  .msgDownload {
    @include typography(12px, 400, 0.4px, 14px, left, rgba(0, 0, 0, 0.6));
  }
  .msgDownloadDate {
    @include typography(12px, 400, 0.4px, 14px, left, rgba(138,0,193,1));
  }
  .detailPopover {

    @include typography(14px, normal, 0.15px, 16px, left, rgba(0, 0, 0, 0.87));
  }
  .detailBold {
    @include typography(14px, 500, 0.15px, 16px, left, rgba(0, 0, 0, 0.87));
  }
  .detailProgress {
    @include typography(14px, 400, 0.15px, 16px, left, rgba(0, 0, 0, 0.6));
  }
  .animacao {
    animation: fadeInFromNone 1s infinite;
  }

}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0.3;
  }

  50% {
    display: block;
    opacity: 1;
  }

  100% {
    display: block;
    opacity: 0.3;
  }
}
