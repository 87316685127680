@import 'src/assets/styles/partials/typography';

.list-content {
  .label {
    @include typography(20px, 500, 0.15px, 23px, left, rgba(0, 0, 0, 0.6));
  }
  .list-box {
    margin-top: 8px;
  }
  .list-item {
    padding-top: 16px;
    padding-bottom: 0px;
    padding-left: 16px;
    height: 32px;
  }
  .text-item {
    @include typography(16px, normal, 0.5px, 19px, left, rgba(0, 0, 0, 0.87));
  }
}
